<template>
  <div>
    <component :is="resolveLayout">
      <!-- <keep-alive :include="routesNameKeepAlives.join(',')"> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </component>

    <TheLoading></TheLoading>
  </div>
</template>

<script>
import BlankLayout from '@pitom84/layouts/src/Blank.vue'
import ContentLayout from '@pitom84/layouts/src/Content.vue'
import defineRulesFor from '@/plugins/casl/ability'
import TheLoading from './components/global/the-loading/TheLoading.vue'

export default {
  name: 'App',

  data() {
    return {
      // routesNameKeepAlives: ['Avaliacoes', 'Avaliações', 'Repasse'],
      scrollPositions: {}
    }
  },

  components: {
    BlankLayout,
    ContentLayout,
    TheLoading
  },

  computed: {
    resolveLayout() {
      if (this.$route.name === null) return null
      if (this.$route.meta.layout === 'blank') return 'blank-layout'
      return 'content-layout'
    }
  },

  // watch: {
  //   $route(to, from) {
  //     if (this.routesNameKeepAlives.includes(from.name)) {
  //       this.scrollPositions[from.name] = window.scrollY
  //     }

  //     if (this.routesNameKeepAlives.includes(to.name)) {
  //       this.$nextTick(() => {
  //         window.scrollTo(0, this.scrollPositions[to.name] || 0)
  //       })
  //     }
  //   }
  // },

  methods: {
    checkTheme() {
      this.$vuetify.theme.dark = localStorage.getItem('app-theme-dark') === 'true'
    },

    onMounted() {
      this.checkTheme()

      let user = localStorage.getItem('user')
      if (user) {
        user = JSON.parse(user)
        this.$ability.update(defineRulesFor(user.user_roles[0].role.name))
      }
    }
  },

  mounted() {
    this.onMounted()
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";
</style>
